import { Inject, Injectable } from '@angular/core';
import { AmplifyAuth } from '@core/interfaces/auth.interface';
import { AWS_AMPLIFY_AUTH, ICommonCoreState, JblCommonUserStateService } from '@jbl-pip/core-state';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root'
})
export class UserStateService extends JblCommonUserStateService<ICommonCoreState> {
	constructor(
		protected override store: Store<ICommonCoreState>,
		@Inject(AWS_AMPLIFY_AUTH) protected override auth: AmplifyAuth
	) {
		super(store, auth);
	}
}
